<template>
  <nav class="navbar navbar-expand-lg shadow">
    <div class="navbar__container">
      <div class="navbar__title">
        <a class="navbar__title__text whitespace-no-wrap" href="/">
          <img class="w-12 mr-2 inline-block" src="@/assets/logo.png" alt="" />
          <span class="text-xs sm:text-base"> {{ school_identity.name }} </span>
        </a>
        <button
          class="navbar__title__toggle"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <fa-icon icon="bars"></fa-icon>
        </button>
      </div>
      <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="navbar__menu-container"
      >
        <ul class="navbar-list" id="navbar-list">
          <li class="nav-item" v-for="(item, key) in menu" :key="key">
            <router-link class="navbar-item__text" :to="item.url">{{
              item.text
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MainMenu",
  data() {
    return {
      menu: [
        { text: "Beranda", url: "/" },
        { text: "Pendaftaran", url: "/registration" },
        { text: "Perkenalan", url: "/profile" },
        { text: "Berita", url: "/news" },
      ],
      showMenu: false,
    };
  },
  props: {},
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
  },
  computed: {
    ...mapGetters(["school_identity"]),
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.navbar {
  @apply fixed top-0 left-0 w-full flex flex-wrap items-center justify-between px-2 py-3  mb-3;
  @apply border-t-2 border-green-500 bg-white text-gray-800;
}

.navbar__container {
  @apply container px-4 mx-auto flex flex-wrap items-center justify-between;
}

.navbar__title {
  @apply w-full relative flex justify-between;

  @screen lg {
    @apply lg:w-auto px-4 lg:static lg:block lg:justify-start;
  }
}

.navbar__title__text {
  @apply font-bold leading-relaxed inline-block mr-4 uppercase;
}

.navbar__title__toggle {
  @apply cursor-pointer text-xl leading-none px-3;
  @apply lg:hidden border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none;
}

.navbar__menu-container {
  @apply lg:flex lg:flex-grow items-center pt-8 lg:pt-0;
}

.navbar-list {
  @apply flex flex-col lg:flex-row list-none ml-auto;
}

.nav-item {
  @apply py-4 lg:py-0 px-4 mx-1;
}

.navbar-item__text {
  @apply flex items-center tracking-wider uppercase leading-snug hover:opacity-75;

  &.router-link-exact-active {
    @apply text-green-600 font-bold;
  }
}
</style>

